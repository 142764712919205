<template>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.commonBg {
  background-image: url("./assets/2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 422px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 300px;
  position: absolute;
  left: 150px;
  top: 10px;
  z-index: 999;
  color: #fff;
  .active {
    border-bottom: 4px solid #fff;
  }
}

.commonBottom {
  position: sticky;
  text-align: center;
  width: 100%;
  z-index: 99999;
  background: whitesmoke;
  vertical-align: middle;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 5px 0;
  }
}
</style>
